import "./services.css";
import React from "react";
import { useState, useEffect } from "react";
import { If, Then, Else } from "react-if";
import {getApi } from "../../util/getApi";
import { DefaultLayout } from "../../layout/main";
import { useParams } from "react-router";
import { onlyDevelopment } from "../../util/onlyDevelopment";
import marked from "marked";
import cheerio from "cheerio";
import { ContentView } from "../../layout/content-view";
import { ApiService } from "../../services/services";

export function Services() {
  const { loaded, service } = ServiceApi();

  return (
    <>
      <DefaultLayout>
        <If condition={loaded}>
          <Then>{service ? <ServiceView service={service} /> : null}</Then>
          <Else>Is Loading</Else>
        </If>
      </DefaultLayout>
    </>
  );
}

function ServiceApi() {
  const [loaded, setLoaded] = useState(false);
  const [service, setService] = useState(null);
  const { type: serviceType } = useParams();

  useEffect(() => {
    ApiService.client()
      .getServiceByRoute(serviceType)
      .then((services) => {
        onlyDevelopment(() => console.log(services));
        setService(services[0]);
        setLoaded(true);
      });
  }, [serviceType]);

  return {
    loaded,
    service,
  };
}

function ServiceView({ service }: any) {
  return (
    <>
      <ContentView>
        <div className="pt-14">{service.content.map(ContentRendered)}</div>
      </ContentView>
    </>
  );
}

function ContentRendered(content) {
  switch (content.__component) {
    case "page-structure.content":
      return MarkdownRenderer(content.text);
    default:
      return null;
  }
}

function MarkdownRenderer(markdown: string) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: HTMLCustomClasses(marked(markdown)) }}
    ></div>
  );
}

function HTMLCustomClasses(html: string) {
  const $ = cheerio.load(html);

  ["h1", "h2", "h3", "h4", "h5"].forEach((h) => {
    $(h).addClass("text-purple-900 capitalize");
  });

  $("h1").addClass("text-5xl mb-12 mt-2 text-purple-900");

  $("h2").addClass("text-3xl mb-6");
  $("h3").addClass("text-2xl mb-4");
  $("h4").addClass("text-xl mb-3");
  $("h5").addClass("text-xl mb-2 italics");
  $("h6").addClass("mb-1 italics");

  $("p").addClass("mb-8 text-xl text-cool-gray-800");

  $("img").each(function (index, element) {
    const el = $(this);
    const originalSrc = el.attr("src");
    el.attr("src", getApi(originalSrc));
    el.addClass("mx-auto my-2 block");
  });

  $("a").addClass("text-blue-600 italic");

  $("ul").addClass("my-5 list-inside");

  $("li").addClass("list-disc");

  return $.html();
}
