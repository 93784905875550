import React, { useState, useEffect,useCallback } from "react";
import { Link } from "react-router-dom";
import join from "url-join";
import { ApiService } from "../services/services";

export function Nav() {
  const { menus,toggleMenu,showMenu } = NavApi();

  return (
    <nav className="shadow py-2">
      <div className="mx-auto p-2 flex flex-row justify-between items-center">
        <Link to="/">
          <div id="nav-left" className="flex flex-row items-center">
            <img src="/logo-tiny.png" alt="noblelaw logo" />
            <div className="flex flex-col px-1 ml-1">
              <h1 className="text-purple-800 font-bold text-lg">
                Noble Law Services
              </h1>
              <h2 className="hidden md:block">
                Paralegal &amp; Immigration Consultant - Notary Public
              </h2>
            </div>
          </div>
        </Link>
        <ul className="hidden md:flex flex-row">{AdjustMenus(menus).map(NavLink)}</ul>
        <i className="fas fa-bars md:hidden" onClick={toggleMenu} />
      </div>
      <div className={`${showMenu?"flex flex-col":"hidden"} md:hidden`}>
        <ul>
          {AdjustMenus(menus).map(NavLink)}
        </ul>
      </div>
    </nav>
  );
}

function AdjustMenus(menu: any[]) {
  return AddHome(CorrectServiceLinks(menu));
}

function AddHome(menus: any[]) {
  return [{ title: "Home", route: "/" }, ...menus];
}

function CorrectServiceLinks(menus: any[]) {
  return menus.map((menu) => {
    return Object.assign({}, menu, { route: join("/service", menu.route) });
  });
}
function NavApi() {
  const [loaded, setLoaded] = useState(false);
  const [menus, setMenus] = useState([]);


  const [showMenu,setMenuDisplay] = useState(false);


  const toggleMenu = useCallback(()=>{
    setMenuDisplay(!showMenu);
  },[showMenu,setMenuDisplay])




  useEffect(() => {
    ApiService.client()
      .getMenus()
      .then((data) => {
        setMenus(data);
        setLoaded(true);
      });
  }, []);

  return { loaded, menus,toggleMenu,showMenu };
}

function NavLink(menu: any) {
  return (
    <li className="p-2 select-none">
      <Link to={menu.route}>{menu.title}</Link>
    </li>
  );
}
