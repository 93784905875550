import React, { useState } from "react";
import "./contact.css";

export function ContactUS() {

  ContactAPI()

  return (
    <>
      <ContactView />
    </>
  );
}

function ContactAPI() {
  const [loaded] = useState(false);

  return { loaded };
}

function ContactView() {

    const messageStyle = "p-2 text-purple-900 font-semibold"
  return (
    <footer className="bg-purple-900">
      <div className="container mx-auto py-20 flex flex-row justify-center">

          <div id="message">
            <h2 className="text-white text-3xl font-extrabold">Quick Link</h2>
            <div className="w-96 bg-purple-200 p-10 mt-10">
            <p className={messageStyle}>
                Navigating the Canadian legal system can be a daunting task, and
                there are many aspects to navigate around.
            </p>
            <p className={messageStyle}>
                Noblelaw handles cases, application and legal matters so you can get
                back to what really matters.
            </p>
            </div>
          </div>

          <div id="image" className="relative">
            
          </div>

      </div>
    </footer>
  );
}
