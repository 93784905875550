import { Api as api } from "ultimate-fetch";
import join from "url-join";
const STAGING = "https://api.noblelaw.ca";
const DEV = "http://localhost:1337";
export function getApi(path:string="") {
  const base = getBase();
  return join(base,path);

}

export function getEnv(){
  return process.env.REACT_APP_BUILD as string || "development"
}

function getBase(){
  switch (getEnv()) {
    case "staging":
      return STAGING;
    default:
      return DEV;
  }
}


export const API = api.create().api(getApi(""));