
import "./Banner.css";
import React from "react";



export interface BackgroundImageProps {
    className?: string;
    src: string;
    children?: any;
}

export function BackgroundImage(props: BackgroundImageProps) {
    return (<>
        <div>
            <div className={"banner-container"} style={{ position: "relative" }}>
                <div className={(props.className || "") + " banner-image banner-container"} style={{ backgroundImage: `url('${props.src}')` }}>
                </div>
                <div className={"banner-container banner-content"}>
                    {props.children}
                </div>
            </div>
        </div>
    </>);
}



export function CardImage(props: BackgroundImageProps) {
    return (<>
        <div>
            <div className={"card-container"} style={{ position: "relative" }}>
                <div className={(props.className || "") + " card-image card-container"} style={{ backgroundImage: `url('${props.src}')` }}>
                </div>
                <div className={"card-container cardcard-content"}>
                    {props.children}
                </div>
            </div>
        </div>
    </>);
}