import React,{useState,useEffect,useMemo} from "react";
import { Else, If, Then } from "react-if";
import { BannerAnimation } from "../../component/BannerAnimation";
import { API, getApi, getEnv } from "../../util/getApi";
import {DefaultLayout} from "../../layout/main";
import { Featured } from "../featured";

export function Home() {

    const {
      loaded,
      bannerProps
    } = HomeApi()

  return (
        <If condition={loaded}>
          <Then>
              <HomeView banner={bannerProps} />
          </Then>
          <Else>Is Loading</Else>
        </If>
  );
}

function HomeApi() {

    const [loaded, setLoaded] = useState(false);
    const [banners, setBanner] = useState([] as any[]);



    const bannerProps = useMemo(()=>{
        return banners.map(banner=>{
            return {
                src:getApi(GetBestBannerImage(banner)),
                list:banner.list.map(l=>l.item)
            }
        })
    },[banners])
  
    useEffect(() => {
      API
        .get("/banners")
        .json()
        .then((data) => {
          setBanner(data);
          setLoaded(true);
        });
    }, []);



    return {loaded,bannerProps}
}

function HomeView({banner}) {
  return (
    <>
      <DefaultLayout>
        {/* <div className=""> */}
            <BannerAnimation banners={banner} autoplay={getAutoPlay()}/>
        {/* </div> */}
        <Featured/>
      </DefaultLayout>
    </>
  );
}


function getAutoPlay(){
  switch(getEnv()){
    case "development":
      return false;
    default:
      return true;

  }
}



function GetBestBannerImage(banner){
  const originalSize = banner.background.size;

  if(originalSize > 1000){
    const format = banner.background.formats;
    const choosen = format.large || format.medium || format.small|| format.thumbnail
    return choosen.url;
  }else{
    return banner.background.url;
  }
}