import * as React from "react";
import { ContactUS } from "../component/contactus";
import { Nav } from "../component/Nav";


export function DefaultLayout(props:any){
    return (
        <>  
        <div className="flex flex-col h-screen">
            <div className="flex-grow-0 bg-white z-10 sticky top-0">
                <Nav />
            </div>
            <div className="flex-grow">
                {props.children}
            </div>
            <div className="flex-grow-0">
                <ContactUS/>
            </div>
        </div>
        </>

    )
}


