import React, { useState, useEffect } from "react";
import { Else, If, Then } from "react-if";
import { Link } from "react-router-dom";
import join from "url-join";
import { ApiService } from "../services/services";

export function Featured() {
  const { loaded, features } = FeaturedApi();

  return (
    <If condition={loaded}>
      <Then>
        <FeaturedView features={features} />
      </Then>
      <Else>Is Loading</Else>
    </If>
  );
}

function FeaturedApi() {
  const [loaded, setLoaded] = useState(false);
  const [features, setFeatures] = useState([] as any[]);

  useEffect(() => {
    ApiService.client()
      .getFeatured()
      .then((data) => {
        setFeatures(data);
        setLoaded(true);
      });
  }, []);

  return { loaded, features };
}

function FeaturedView({ features }) {
  return (
    <>
      <div className="flex flex-col p-2 xl:p-0 xl:flex-row container mx-auto items-center xl:justify-between xl:items-stretch my-36">
        {features.slice(0, 3).map((f) => (
          <FeaturedItem key={f.id} feature={f} />
        ))}
      </div>
    </>
  );
}

function FeaturedItem({ feature }) {
  return (
    <>
      <div className="p-2 md:px-10 flex flex-col justify-between">
        <div>
          <h3 className="text-2xl text-purple-900 font-bold mb-5 capitalize">
            {feature.title}
          </h3>
          <div className="text-lg max-w-lg lg:mx-w-none whitespace-pre-wrap">
            {feature.description}
          </div>
        </div>
        <div className="mt-2 text-purple-900 font-black">
          <Link to={join("/service", feature.route)}>More Information</Link>
        </div>
      </div>
    </>
  );
}
