import React, { useLayoutEffect } from "react";
import { BackgroundImage } from "./image/BackgroundImage";



const $ = (window as any).jQuery;
export interface BannerProp{
    src:string;
    list:string[]
}

export function BannerAnimation(
    {
        banners,
        autoplay
    }
    :
    {
        banners:BannerProp[]
        autoplay?:boolean
    }) {

    const layoutClass = "flex flex-col justify-end items-end h-full w-full p-3 text-white";
    // var settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    // };

    useLayoutEffect(() => {

        $('.banner').slick({
            autoplay: autoplay??true,
            dots: true,
            mobileFirst: true
        });

    }, [autoplay])


    return (
        <>
            <div className="banner">
                {banners.map(banner=>{
                    return (
                        <BackgroundImage src={banner.src}>
                            <ul  className={layoutClass}>
                            {
                                banner.list.map(item=>{
                                    return (
                                        <li className="text-2xl" key={item}>
                                            {item}
                                        </li>
                                    )
                                })
                            }
                            </ul>
                        </BackgroundImage>
                    );
                })}
            </div>
        </>
    )
}
