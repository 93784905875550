import { API } from "../util/getApi";
import { IServicePage } from "../interface/Featured";
const DEFAULT_PRIORITY = 1000;

export class ApiService {
  async getFeatured(): Promise<IServicePage[]> {
    return API.get("/service-pages?featured=true").json().then(this.sortByPriority);
  }

  async getServiceByRoute(route: string): Promise<IServicePage[]> {
    return API.get(`/service-pages?route=${route}`).json().then(this.sortByPriority);
  }


  async getMenus(){
      const results = await  API.get("/service-pages?menu=true")
      .json().then(this.sortByPriority);
      return results;
  }


  private sortByPriority(pages:IServicePage[]){
    const pages_with_priority = pages.sort((a,b)=>{
        return (a.priority||DEFAULT_PRIORITY) - (b.priority||DEFAULT_PRIORITY)
    })

    return pages_with_priority;
  }

  static client() {
    return new ApiService();
  }
}
