import React from "react";
import "./content-view.css";

export function ContentView(props:any){
    return (
        <div className="bg-cool-gray-100 flex flex-col items-center ">
            <div className="container md:pb-20 mx-auto bg-white px-5 md:px-20 lg:mx-20 content">
                {props.children}
            </div>
        </div>
    );
}